interface JobCardProps {
  className?: string;
  company: string;
  title: string;
  posted: string;
  icon: string;
}

export const JobCard = ({
  className,
  icon,
  company,
  title,
  posted,
}: JobCardProps) => {
  return (
    <div
      className={`border-solid border-2 border-gray-800/50 bg-gray-800/30 w-fit rounded-2xl ${className}`}
    >
      <div className="relative flex flex-col md:flex-row flex-row p-4">
        <div className="absolute top-0 right-0 w-4 h-4 -mr-2 -mt-2 rounded-full bg-lime-500/60" />
        <img src={icon} alt="Company Logo" className="w-10 h-10 m-8" />
        <div className="m-auto">
          <h3 className="text-white opacity-80 font-bold text-xl lg:text-2xl">
            {title}
          </h3>
          <p className="text-gray-400 text-sm">
            {company} - {posted}
          </p>
        </div>
      </div>
    </div>
  );
};
