import type { PropsWithChildren } from "react";
import { Footer } from "./Footer";
import { Header } from "./Header";
import { JobCard } from "./JobCard";

interface LayoutProps {
  smallBg?: boolean;
  center?: boolean;
  header?: boolean;
  blurBackground?: boolean;
  authenticated?: boolean;
  wide?: boolean;
}

export const Layout = ({
  children,
  smallBg,
  center = true,
  header = false,
  blurBackground = true,
  authenticated,
  wide,
}: PropsWithChildren<LayoutProps>) => {
  const width = wide ? "max-w-6xl" : "max-w-4xl";

  return (
    <div className="relative text-white flex flex-col isolate bg-gray-900">
      <div className="overflow-hidden absolute -z-10 right-0 left-0 h-full">
        <div
          className={`${blurBackground ? "animate-blur" : ""} absolute top-0 ${
            smallBg
              ? "scale-50 md:scale-75 -mr-40 -mt-14"
              : "scale-75 md:scale-75 lg:scale-110 -mr-40 md:-mr-24 -mt-16 sm:-mt-12 lg:mt-14"
          } right-0 -z-10`}
        >
          <JobCard
            icon="/nficon2016.png"
            className="-ml-14"
            company="Netflix"
            title="Software Engineer"
            posted="1d ago"
          />
          <JobCard
            icon="/android-icon-192x192.png"
            company="Reddit"
            className={`${smallBg ? "scale-50" : "scale-75"} -mt-8 md:-mt-7`}
            title="Senior Software Engineer"
            posted="4d ago"
          />
        </div>
        <svg
          className="absolute inset-0 -z-10 h-full w-full stroke-white/10 [mask-image:radial-gradient(100%_100%_at_top_right,white,transparent)]"
          aria-hidden="true"
        >
          <defs>
            <pattern
              id="983e3e4c-de6d-4c3f-8d64-b9761d1534cc"
              width={200}
              height={200}
              x="50%"
              y={-1}
              patternUnits="userSpaceOnUse"
            >
              <path d="M.5 200V.5H200" fill="none" />
            </pattern>
          </defs>
          <svg x="50%" y={-1} className="overflow-visible fill-gray-800/20">
            <path
              d="M-200 0h201v201h-201Z M600 0h201v201h-201Z M-400 600h201v201h-201Z M200 800h201v201h-201Z"
              strokeWidth={0}
            />
          </svg>
          <rect
            width="100%"
            height="100%"
            strokeWidth={0}
            fill="url(#983e3e4c-de6d-4c3f-8d64-b9761d1534cc)"
          />
        </svg>
        <svg
          viewBox="0 0 1108 632"
          aria-hidden="true"
          className="absolute top-10 left-[calc(50%-4rem)] -z-10 w-[69.25rem] max-w-none transform-gpu blur-2xl sm:left-[calc(50%-18rem)] lg:left-48 lg:top-[calc(50%-30rem)] xl:left-[calc(50%-24rem)]"
        >
          <path
            fill="url(#175c433f-44f6-4d59-93f0-c5c51ad5566d)"
            fillOpacity=".2"
            d="M235.233 402.609 57.541 321.573.83 631.05l234.404-228.441 320.018 145.945c-65.036-115.261-134.286-322.756 109.01-230.655C968.382 433.026 1031 651.247 1092.23 459.36c48.98-153.51-34.51-321.107-82.37-385.717L810.952 324.222 648.261.088 235.233 402.609Z"
          />
          <defs>
            <linearGradient
              id="175c433f-44f6-4d59-93f0-c5c51ad5566d"
              x1="1220.59"
              x2="-85.053"
              y1="432.766"
              y2="638.714"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#4F46E5" />
              <stop offset={1} stopColor="#80CAFF" />
            </linearGradient>
          </defs>
        </svg>
      </div>
      <div
        className={`${
          center ? "m-auto" : ""
        } flex w-full z-10 min-h-screen h-full`}
      >
        <div
          className={`flex flex-col max-h-fit ${
            center ? "lg:text-center" : ""
          } flex-grow  px-5 lg:px-8 mx-auto ${width}
          `}
        >
          {header && <Header authenticated={Boolean(authenticated)} />}
          <div
            className={`mt-8 ${
              center ? "py-20 lg:py-40 m-auto" : "mx-auto"
            } ${width} w-full overflow-clip`}
          >
            {children}
          </div>
        </div>
      </div>
      <div
        className={`flex max-w-7xl w-full mt-28 mx-auto px-6 pb-24 sm:pb-32`}
      >
        <Footer />
      </div>
    </div>
  );
};
