import { Link } from "@remix-run/react";
import { Button } from "./Button";

interface HeaderProps {
  authenticated: boolean;
}

export const Header = ({ authenticated }: HeaderProps) => {
  const linkClasses =
    "my-auto hover:underline decoration-lime-400 text-gray-200 text-xl font-bold";
  return (
    <div className="w-full gap-2 mx-auto py-4 mt-8 flex flex-nowrap justify-start">
      <Link
        to="/"
        className="flex items-center gap-2 text-2xl font-bold text-gray-300"
      >
        {authenticated ? (
          <>
            <span className="hidden md:inline-block">Next Level Jobs</span>{" "}
            <span className="inline-block md:hidden">Next Level</span>
            <span className="hidden md:inline-block text-gray-400 hover:text-lime-400 italic">
              EU
            </span>
          </>
        ) : (
          <>
            Next Level Jobs
            <span className="text-gray-400 hover:text-lime-400 italic">EU</span>
          </>
        )}
      </Link>
      <div className="md:w-fit h-full justify-between ml-auto mr-0 mx-auto flex gap-6">
        {authenticated ? (
          <>
            <Button
              size="md"
              variant="secondary"
              to="/logout"
              className="my-auto"
            >
              Logout
            </Button>
            <Button
              size="md"
              variant="outlined"
              to="/account"
              className="my-auto"
            >
              Account
            </Button>
          </>
        ) : (
          <>
            <Link
              to="/login"
              className={`${linkClasses} my-auto whitespace-nowrap hidden md:inline-block`}
            >
              Log in
            </Link>
            <Button
              variant="outlined"
              size="sm"
              to="/signup"
              className={`${linkClasses} my-auto whitespace-nowrap`}
            >
              Sign up
            </Button>
          </>
        )}
      </div>
    </div>
  );
};
